<template>
  <v-dialog v-model="addSalesTeamDialog" scrollable width="800">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon
                  class="text-2xl font-semibold"
                  :text="`${
                    salesTeam.id != null ? 'Update ' : 'Add '
                  } Sales Team`"
                  style="color: black"
                >
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="cancelSalesTeam">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <div class="row row--dense mt-5">
            <v-col sm="6" md="3" lg="3">
              <image-uploader
                @upload="
                  (data) => {
                    salesTeam.profile_image = data;
                  }
                "
                :image_path="salesTeam.image_path"
                :height="160"
                defaultImage="user"
                @remove="
                  () => {
                    salesTeam.profile_image = null;
                  }
                "
                ref="image_upload"
                text="Sales Person Picture"
                messageText=""
              ></image-uploader>
            </v-col>

            <v-col md="9" lg="9">
              <v-row>
                <v-col md="6" lg="6">
                  <label for="">Mobile No*</label>
                  <v-mobile-search
                    :selected="salesTeam.mobile"
                    :isUserCheck="true"
                    @updateCustomer="setCustomerData"
                    v-model="salesTeam.search"
                    :show-label="false"
                    class-name1="q-text-field shadow-0 "
                    class-name2="q-text-field shadow-0 mobile_auto_complete_hide_anchor"
                    :dense="true"
                    hide-details="auto"
                    :show-flag="true"
                  ></v-mobile-search>
                </v-col>
                <v-col md="6" lg="6">
                  <label for="">Email*</label>
                  <v-text-field
                    v-model="salesTeam.email"
                    outlined
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    required
                    :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col md="6" lg="6">
                  <label for="">First Name*</label>
                  <v-text-field
                    v-model="salesTeam.first_name"
                    outlined
                    required
                    :rules="[(v) => !!v || 'First name is required']"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col md="6" lg="6">
                  <label for="">Last Name*</label>
                  <v-text-field
                    v-model="salesTeam.last_name"
                    outlined
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    validate-on-blur
                    required
                    :rules="[(v) => !!v || 'Last name is required']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </div>

          <v-row dense class="mt-4">
            <v-col cols="12" sm="6" md="6" v-if="!isExistingUser && !editFlag">
              <label for="">Username*</label>
              <v-text-field
                v-model="salesTeam.username"
                outlined
                autocomplete="off"
                :name="`username_${Math.random()}`"
                required
                :rules="[(v) => !!v || 'Username is required']"
                class="q-text-field shadow-0"
                dense
                hide-details="auto"
                validate-on-blur
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="!isExistingUser && !editFlag">
              <label for="">Password*</label>
              <v-text-field
                :type="salesTeam.hidePassword1 ? 'text' : 'password'"
                :append-icon="
                  salesTeam.hidePassword1 ? 'mdi-eye' : 'mdi-eye-off'
                "
                outlined
                class="q-text-field shadow-0"
                dense
                hide-details="auto"
                validate-on-blur
                v-model="salesTeam.password"
                @click:append="
                  salesTeam.hidePassword1 = !salesTeam.hidePassword1
                "
                required
                autocomplete="new-password"
                :name="`password_${Math.random()}`"
                :rules="[(v) => !!v || 'Password is required']"
              />
            </v-col>
            <v-col cols="12" md="6" v-if="!isExistingUser && !editFlag">
              <label for="">Confirm Password*</label>
              <v-text-field
                :type="salesTeam.hidePassword2 ? 'text' : 'password'"
                :append-icon="
                  salesTeam.hidePassword2 ? 'mdi-eye' : 'mdi-eye-off'
                "
                outlined
                :autocomplete="`sales-team-password-confirm-${parseInt(
                  salesTeam.name
                )}`"
                v-model="salesTeam.password_confirm"
                @click:append="
                  salesTeam.hidePassword2 = !salesTeam.hidePassword2
                "
                class="q-text-field shadow-0"
                dense
                hide-details="auto"
                validate-on-blur
                required
                :rules="[
                  (v) => !!v || 'Password is required',
                  (v) =>
                    v == this.salesTeam.password || 'Password does not match',
                ]"
              />
            </v-col>

            <v-col cols="12" md="6" v-if="!isExistingUser && !editFlag">
              <label for="">Roles*</label>
              <v-select
                v-model="salesTeam.role_id"
                :items="roles"
                item-value="id"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="title"
                :rules="[(v) => !!v || 'Role is required']"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
                validate-on-blur
              ></v-select>
            </v-col>

            <div class="col-md-12 d-flex gap-x-4">
              <label for="" class="d-flex text-center align-center"
                >Enable Online</label
              >
              <v-switch
                :false-value="0"
                :true-value="1"
                v-model="salesTeam.is_public"
                class="d-flex text-center align-center"
              ></v-switch>
            </div>

            <v-col
              :md="12"
              v-if="checkWritePermission($modules.salesTeam.commission.slug)"
            >
              <div class="blue-text text-lg font-bold">Commission</div>
              <v-card
                rounded
                flat
                class="mt-4 rounded-5 bordered"
                v-for="(commission, k) in salesTeam.commissions"
                :key="'d_' + k"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <label for="">Type*</label>
                      <v-select
                        v-model="commission.product_type_id"
                        :items="productTypes"
                        outlined
                        item-text="name"
                        item-value="id"
                        :rules="[(v) => !!v || 'Product Type is required']"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        validate-on-blur
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <label for="">Commission (%)*</label>
                      <v-text-field
                        required
                        outlined
                        v-model="commission.commission_percentage"
                        @input="handleInputChange(k)"
                        :rules="[(v) => !!v || 'Commission is required']"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"
                        validate-on-blur
                      ></v-text-field>
                    </v-col>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteCommission(k)"
                          fab
                          x-small
                          absolute
                          top
                          right
                          class="shadow-0"
                        >
                          <DeleteIcon />
                        </v-btn>
                      </template>
                      Delete
                    </v-tooltip>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              md="12"
              v-if="checkWritePermission($modules.salesTeam.commission.slug)"
            >
              <v-btn
                color="teal-color"
                dark
                class="shadow-0"
                @click="addCommission"
              >
                + Add Commission
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" text @click="cancelSalesTeam">Close</v-btn>

          <v-btn
            class="ma-2 white--text blue-color"
            @click="addOrUpdateSalesTeam"
            >{{ salesTeam.id != null ? "Update" : "Save" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import DeleteIcon from "@/assets/images/misc/delete-bg-icon.svg";

export default {
  components: { ImageUploader, SvgIcon, DeleteIcon },
  props: {
    addSalesTeamDialog: { type: Boolean, default: false },
    stID: { type: Number, default: null },
    roles: { type: Array, default: null },
  },

  mounted() {
    this.salesTeamDetails(this.stID);
  },
  computed: {
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter(
        (service) => service.name != "POS"
      );
    },
  },
  data() {
    return {
      maxValue: 100,
      commissionError: false,
      percentageRules: [
        (value) => {
          if (value && !/^\d+(\.\d+)?%?$/.test(value)) {
            return "Invalid percentage format";
          }
          return true;
        },
      ],
      salesTeam: {
        is_public: 1,
        first_name: null,
        last_name: null,
        designation: null,
        email: null,
        mobile: null,
        profile_image: null,
        username: null,
        password: null,
        password_confirm: null,
        hidePassword2: false,
        hidePassword1: false,
        venue_service_ids: [],
        status_id: 1,
        user_id: null,
        commission_percentage: null,
        commissions: [
          {
            commission_percentage: null,
            product_type_id: null,
          },
        ],
        deletedCommissions: [],
      },
      isExistingUser: true,
      page: 1,
      btnShow: false,
      venueServiceIds: [],
      salesTeamStatus: null,
      salesTeamList: [],
      totalPages: null,
      valid: true,
      editFlag: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      total_sales: 0,
      total_commission: 0,
    };
  },

  methods: {
    toggleSelect() {
      this.$nextTick(() => {
        if (this.venueServices.length == this.venueServiceIds.length) {
          this.venueServiceIds = [];
        } else {
          this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.loadSalesTeams();
      });
    },
    getIcon() {
      if (this.venueServiceIds.length == 0) return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.venueServiceIds.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    toggleSelectSalesTeam() {
      this.$nextTick(() => {
        if (
          this.venueServices.length == this.salesTeam.venue_service_ids.length
        ) {
          this.salesTeam.venue_service_ids = [];
        } else {
          this.salesTeam.venue_service_ids = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.loadSalesTeams();
      });
    },
    getIconSalesTeam() {
      if (this.salesTeam.venue_service_ids.length == 0)
        return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.salesTeam.venue_service_ids.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    loadSalesTeams() {
      let url = `&status=${this.salesTeamStatus}&per_page=9`;
      this.showLoader("Loading SalesTeams");
      if (this.venueServiceIds.length > 0) {
        this.venueServiceIds.map((service, index) => {
          url += `&venue_service_id[${index}]=${service}`;
        });
      }
      this.$http
        .get(`venues/sales-team?page=${this.page}${url}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.salesTeamList = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    handleInputChange(index) {
      // Limit the input value to the maximum value
      if (
        this.salesTeam.commissions[index].commission_percentage !== null &&
        this.salesTeam.commissions[index].commission_percentage > this.maxValue
      ) {
        this.showError("Commession should be less than 100.");
        return;
      }
    },
    addSalesTeam() {
      this.salesTeam = {
        is_public: 1,
        first_name: null,
        last_name: null,
        designation: null,
        email: null,
        mobile: null,
        profile_image: null,
        username: null,
        password: null,
        password_confirm: null,
        hidePassword2: false,
        hidePassword1: false,
        venue_service_ids: [],
        status_id: 1,
        is_existing_user: false,
        user_id: null,
        commissions: [],
        deletedCommissions: [],
      };

      this.editFlag = false;
      //   setTimeout(() => {
      //     this.$refs["image_upload"].cancel();
      //     this.$refs.form.resetValidation();
      //   });
    },
    setCustomerData(data) {
      console.log(data);
      if (data.email == undefined) {
        this.isExistingUser = false;
        this.salesTeam.user_id = null;
        this.salesTeam.first_name = null;
        this.salesTeam.email = null;
        this.salesTeam.last_name = null;
      } else {
        this.isExistingUser = true;
      }
      if (!data.customer_id) {
        this.$set(this.salesTeam, "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.salesTeam, "name", data.first_name);
      }

      if (
        this.salesTeam.customer_id &&
        !data.customer_id &&
        this.salesTeam.name != data.name &&
        this.salesTeam.mobile != data.mobile
      ) {
        this.$set(this.salesTeam, "mobile", null);
        this.salesTeam.search = null;
        this.salesTeam.nameSearch = null;
        this.$set(this.salesTeam, "email", null);
        this.$set(this.salesTeam, "name", null);
        this.$set(this.salesTeam, "customer_id", null);
        this.$set(this.salesTeam, "first_name", null);
        this.$set(this.salesTeam, "image_path", null);
        this.$set(this.salesTeam, "dob", null);
        this.$set(this.salesTeam, "country_id", null);
        this.$set(this.salesTeam, "last_name", null);
        this.$set(this.salesTeam, "user_id", null);
        this.$forceUpdate();
      }

      if (data.mobile) this.$set(this.salesTeam, "mobile", data.mobile);
      if (data.email) this.$set(this.salesTeam, "email", data.email);

      if (data.name) this.$set(this.salesTeam, "name", data.name);
      if (data.last_name) {
        this.$set(this.salesTeam, "last_name", data.last_name);
      } else {
        this.$set(this.salesTeam, "last_name", null);
      }
      if (data.first_name)
        this.$set(this.salesTeam, "first_name", data.first_name);
      if (data.customer_id)
        this.$set(this.salesTeam, "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.salesTeam, "image_path", data.image_path);
      } else {
        this.$set(this.salesTeam, "image_path", null);
      }
      if (data.user_id) this.$set(this.salesTeam, "user_id", data.user_id);
      this.$forceUpdate();
    },
    addOrUpdateSalesTeam(e) {
      if (e != null) {
        e.preventDefault();
        console.log(this.$refs.form);
        if (!this.$refs.form.validate()) {
          console.log(this.$refs.form.first_name);
          this.showError("Please fill all required fields");
          return;
        }
      }

      let formData = new FormData();
      this.showLoader();
      if (
        this.salesTeam.profile_image != null &&
        typeof this.salesTeam.profile_image != "string"
      )
        formData.append("image_path", this.salesTeam.profile_image);
      formData.append("is_public", this.salesTeam.is_public);
      formData.append("first_name", this.salesTeam.first_name);
      formData.append("last_name", this.salesTeam.last_name);
      formData.append("email", this.salesTeam.email);
      formData.append("mobile", this.salesTeam.mobile);
      if (this.salesTeam.username != null && this.salesTeam.username != "") {
        formData.append("username", this.salesTeam.username);
      }
      formData.append("designation", this.salesTeam.designation);
      formData.append("is_existing_user", this.isExistingUser);
      if (this.salesTeam.user_id != null && this.salesTeam.user_id != undefined)
        formData.append("user_id", this.salesTeam.user_id);

      console.log(this.salesTeam.commissions);
      if (this.salesTeam.deletedCommissions.length) {
        this.salesTeam.deletedCommissions.forEach((element, index) => {
          formData.append(`deleted_commissions[${index}]`, element);
        });
      }
      this.commissionError = false;
      this.salesTeam.commissions.forEach((element, index) => {
        Object.keys(element).forEach((key) => {
          if (element[key] != null) {
            if (Number(element[key]) <= 100)
              formData.append(`commissions[${index}][${key}]`, element[key]);
            else {
              this.commissionError = true;
            }
          }
        });
      });
      if (this.commissionError) {
        this.hideLoader();
        this.showError("Commission should be less than or equal to 100");
        return;
      }
      if (this.salesTeam.password)
        formData.append("password", this.salesTeam.password);
      if (this.salesTeam.role_id)
        formData.append("role_id", this.salesTeam.role_id);

      formData.append("status_id", this.salesTeam.status_id);
      if (this.salesTeam.venue_service_ids.length)
        this.salesTeam.venue_service_ids.forEach((venueServiceId, index) => {
          formData.append(`venue_service_ids[${index}]`, venueServiceId);
        });
      this.$http
        .post(
          "venues/sales-team" +
            (this.salesTeam.id != null ? `/${this.salesTeam.id}` : ""),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message = response.data.message;
            this.showSuccess(message);
            this.hideLoader();
            this.loadSalesTeams();
            this.$emit("cancelSalesTeam");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    cancelSalesTeam() {
      this.$emit("cancelSalesTeam");
      this.$refs.form.resetValidation();
    },
    salesTeamDetails(id) {
      this.salesTeam = {
        is_public: 1,
        first_name: null,
        last_name: null,
        designation: null,
        email: null,
        mobile: null,
        profile_image: null,
        username: "",
        password: null,
        password_confirm: null,
        hidePassword2: false,
        hidePassword1: false,
        venue_service_ids: [],
        status_id: 1,
        is_existing_user: false,
        user_id: null,
        commissions: [],
        deletedCommissions: [],
      };
      if (!id) return;
      this.$http
        .get(`venues/sales-team/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.salesTeam = response.data.data;
            console.log(this.salesTeam);
            this.salesTeam.status_id = this.salesTeam.status_id.toString();
            this.salesTeam.profile_image = this.salesTeam.image_path
              ? this.salesTeam.image_path
              : null;
            this.salesTeam.venue_service_ids =
              this.salesTeam.sales_team_services.map(
                (item) => item.venue_service_id
              );
            if (this.salesTeam.commissions.length == 0) {
              this.salesTeam.commissions = [];
            }
            this.salesTeam.deletedCommissions = [];
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    confirmDelete(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${
          data.status_id == 1 ? "delete" : "reactivate"
        } this Sales Person?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "salesTeam",
        data: {
          flag: data.flag ? true : false,
        },
      };
    },
    confirmActions(data) {
      if (data.type == "salesTeam") this.changeSalesTeamStatus(data.id);
      if (data.type == "delete_commission") {
        if (this.salesTeam.commissions[data.id].id != null) {
          this.salesTeam.deletedCommissions.push(
            this.salesTeam.commissions[data.id].id
          );
        }
        this.salesTeam.commissions.splice(data.id, 1);
        if (this.salesTeam.commissions.length == 0) {
          this.salesTeam.commissions = [
            {
              commission_percentage: null,
              product_type_id: null,
            },
          ];
        }
      }

      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    changeSalesTeamStatus(id) {
      this.$http
        .put(`venues/sales-team/${id}/status`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.loadSalesTeams();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    deleteCommission(index) {
      if (this.salesTeam.commissions[index].id) {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this commission?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_commission",
        };
      } else {
        this.salesTeam.commissions.splice(index, 1);
        if (this.salesTeam.commissions.length == 0) {
          this.salesTeam.commissions = [];
        }
      }
    },
    addCommission() {
      console.log(this.salesTeam.commissions);
      this.salesTeam.commissions.push({});
    },
  },
};
</script>