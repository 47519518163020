<template>
  <v-card style="border-radius: 8px" class="shadow trainer_card"   v-if="checkReadPermission($modules.salesTeam.management.slug)"
          @click="SalesTeamView">
    <v-card-text>
      <v-row class="border-bottom">
        <v-col md="4">
          <view-image :image="image_path" defaultImage="user" style="border-radius: 0.5rem;" :height="130" ></view-image>
        </v-col>
        <v-col md="8">

          <div class="mt-4">
            <SvgIcon :text="first_name" class="m-b-3 font-semibold text-2xl black--text text-elepsis"/>

            <SvgIcon :text="email" class="gap-x-4 m-b-3 font-medium">
              <template v-slot:icon>
                <SMSIcon/>
              </template>
            </SvgIcon>
            <SvgIcon class="gap-x-4 m-b-3"
            >
              <template v-slot:icon>
                <CallIcon/>
              </template>
              <div>
                <p class="m-b-0 font-medium">
                    {{mobile}}
                </p>

              </div>
            </SvgIcon>
          </div>
        </v-col>
      </v-row>


      <v-row class="mt-4">
        <v-col
            :cols="getWidth()"
            v-if="checkReadPermission($modules.salesTeam.commission.slug)"
        >
          <div class="sales_team_stats text-center ">
          <p class="text-lg font-medium text-blue">{{ Number(total_commission) | toCurrencyWithoutCode }} <span class="text-sm">{{store.getters.currencyCode}}</span></p>
          <p class="text-base text-dark-gray"> Commission </p>
        </div>
        </v-col>
        <v-col
            v-if="checkReadPermission($modules.salesTeam.revenue.slug)"
            :cols="getWidth()"
        >
          <div class="sales_team_stats text-center">
            <p class="text-lg font-medium text-blue">{{ Number(total_sales) | toCurrencyWithoutCode }} <span class="text-sm">{{store.getters.currencyCode}}</span></p>
            <p class="text-base text-dark-gray">Sales</p>
          </div>

        </v-col>
      </v-row>

    </v-card-text>
    <v-bottom-navigation v-if="1==4">
      <v-spacer
        v-if="checkWritePermission($modules.salesTeam.dashboard.slug)"
      ></v-spacer>
      <v-btn
        v-if="checkWritePermission($modules.salesTeam.dashboard.slug)"
        text
        @click="$emit('edit', id)"
      >
        <span>Edit</span>
        <v-icon medium>mdi-pencil</v-icon>
      </v-btn>
      <v-spacer
        v-if="
          checkWritePermission($modules.salesTeam.dashboard.slug) ||
            checkReadPermission($modules.salesTeam.management.slug)
        "
      ></v-spacer>
      <v-btn
        v-if="checkReadPermission($modules.salesTeam.management.slug)"
        @click="SalesTeamView"
      >
        <span>View</span>
        <v-icon> mdi-eye </v-icon>
      </v-btn>
      <v-spacer
        v-if="
          checkReadPermission($modules.salesTeam.management.slug) ||
            checkDeletePermission($modules.salesTeam.dashboard.slug)
        "
      ></v-spacer>
      <v-btn
        v-if="checkDeletePermission($modules.salesTeam.dashboard.slug)"
        @click="$emit('delete', { id: id, status_id: status_id })"
      >
        <span>{{
          status_id == 1 || status_id == 16 ? "Delete" : "Reactivate"
        }}</span>
        <v-icon>
          mdi-{{ status_id == 1 || status_id == 16 ? "delete" : "refresh" }}
        </v-icon>
      </v-btn>
      <v-spacer
        v-if="checkDeletePermission($modules.salesTeam.dashboard.slug)"
      ></v-spacer>
    </v-bottom-navigation>
  </v-card>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import SMSIcon from "@/assets/images/misc/sms.svg";
import CallIcon from "@/assets/images/misc/call.svg";
import store from "@/store";

export default {
  components: {SvgIcon , SMSIcon , CallIcon},
  props: {
    id: { type: Number, default: 0 },
    first_name: { type: String, default: "" },
    last_name: { type: String, default: "" },
    designation: { type: String, default: "" },
    email: { type: String, default: "" },
    mobile: { type: String, default: "" },
    total_sales: { type: Number, default: 0 },
    total_commission: { type: Number, default: 0 },
    status_id: { type: Number, default: 1 },
    image_path: {
      type: String,
      default: null,
    },
    services: { type: String, default: "" },
  },
  computed: {
    store() {
      return store
    }
  },
  methods: {
    getWidth() {
      return this.checkReadPermission(this.$modules.trainers.commission.slug)
        ? 6
        : 4;
    },
    SalesTeamView() {
      this.$router.push({
        name: "SalesTeamView",
        params: { data: btoa(this.id) },
      });
    },
  },
};
</script>

<style scoped>
.button-style {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1%;
  padding-right: 2%;
}


.trainer_card {
  border: 1px solid rgba(17, 42, 70, 0);

  cursor: pointer;
}

.trainer_card:hover{
  border: 1px solid rgba(17, 42, 70, 1);
}

.sales_team_stats{
  p{
    margin: 0;
    padding: 0;
  }
}
</style>
