<template>
  <v-container fluid>

    <v-row>
      <!-- <v-col class="ml-2" lg="2" sm="2">
        <v-autocomplete
          :items="venueServices"
          v-model="venueServiceIds"
          item-value="venue_service_id"
          item-text="name"
          label="Service"
          outlined
          multiple
          @change="loadSalesTeams"
          background-color="#fff"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleSelect()">
              <v-list-item-action>
                <v-icon
                  :color="venueServiceIds.length > 0 ? 'indigo darken-4' : ''"
                  >{{ getIcon() }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template
            v-if="venueServiceIds.length == venueServices.length"
            v-slot:selection="{ index }"
          >
            <span v-if="index == 0">All Services</span>
          </template>
          <template v-else v-slot:selection="{ item, index }">
            <span v-if="index == 0">{{ item.name }}</span>
            <span v-if="index == 1">, {{ item.name }}</span>
            <span v-if="index == 2">, ...</span>
          </template>
        </v-autocomplete>
      </v-col> -->
      <v-col lg="2" sm="2" class="text-center"> </v-col>
      <v-col lg="8" sm="8" class="text-center">
        <v-btn-toggle
          borderless
          class="q-tabs shadow-0"
          v-model="salesTeamStatus"
          mandatory
          @change="changeActiveInactiveTab"
        >
          <v-btn value="active">Active</v-btn>
          <v-btn value="inactive">Inactive</v-btn>
          <!-- <v-btn value="external">External</v-btn> -->
        </v-btn-toggle>
      </v-col>
      <v-col class="text-lg-right">
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              dark
              height="45"
              class="mr-2 blue-color"
              @click="gotoConfiguration"
              >Configuration <v-icon right dark>mdi-cog</v-icon></v-btn
            >
          </template>
          Configuration
        </v-tooltip> -->

        <v-btn
            v-if="checkWritePermission($modules.salesTeam.management.slug)"
            color=" darken-1"
            class="mr-6 white--text blue-color"
            @click="addSalesTeam"
            dark
            height="45"
        >
          <AddIcon/> <span class="ml-1">Add Sales Team</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="salesTeam in salesTeamList" :key="salesTeam.id" lg="4" md="4" xl="3" sm="12">
        <sales-team-widget
          v-bind="salesTeam"
          @edit="salesTeamDetails"
          @delete="confirmDelete"
        />
      </v-col>
    </v-row>
    <h3 class="text-center mt-12" v-if="salesTeamList.length == 0">
      No Sales Teams
    </h3>

    <v-pagination
      v-if="totalPages"
      v-model="page"
      :length="totalPages"
      class="mt-3 new-pagination"
    ></v-pagination>
    <sales-team-add-edit-modal
        :add-sales-team-dialog="addSalesTeamDialog"
        @cancelSalesTeam="cancelSalesTeam"
        :roles="roles"
    />

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import SalesTeamWidget from "@/components/SalesTeam/SalesTeamWidget.vue";
import SalesTeamAddEditModal from "@/views/SalesTeam/SalesTeamAddEditModal.vue";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
export default {
  components: {
    AddIcon,
    SalesTeamAddEditModal,
    SalesTeamWidget,
  },
  mounted() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
    this.getVenueRolesList();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  computed: {
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter(
        (service) => service.name != "POS"
      );
    },
  },
  watch: {
    page() {
      this.loadSalesTeams();
    },
  },
  data() {
    return {
      maxValue: 100,
      commissionError: false,
      percentageRules: [
        (value) => {
          if (value && !/^\d+(\.\d+)?%?$/.test(value)) {
            return "Invalid percentage format";
          }
          return true;
        },
      ],
      salesTeam: {
        is_public: 1,
        first_name: null,
        last_name: null,
        designation: null,
        email: null,
        mobile: null,
        profile_image: null,
        username: null,
        password: null,
        password_confirm: null,
        hidePassword2: false,
        hidePassword1: false,
        venue_service_ids: [],
        status_id: 1,
        user_id: null,
        commission_percentage: null,
        commissions: [
          {
            commission_percentage: null,
            product_type_id: null,
          },
        ],
        deletedCommissions: [],
      },
      isExistingUser: true,
      roles: [],
      page: 1,
      btnShow: false,
      venueServiceIds: [],
      salesTeamStatus: null,
      salesTeamList: [],
      addSalesTeamDialog: false,
      totalPages: null,
      valid: true,
      editFlag: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      total_sales: 0,
      total_commission: 0,
    };
  },
  methods: {
    getVenueRolesList() {
      this.$http
        .get("venues/roles")
        .then((response) => {
          if (response.status == 200) this.roles = response.data.data;
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (this.venueServices.length == this.venueServiceIds.length) {
          this.venueServiceIds = [];
        } else {
          this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.loadSalesTeams();
      });
    },
    getIcon() {
      if (this.venueServiceIds.length == 0) return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.venueServiceIds.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    toggleSelectSalesTeam() {
      this.$nextTick(() => {
        if (
          this.venueServices.length == this.salesTeam.venue_service_ids.length
        ) {
          this.salesTeam.venue_service_ids = [];
        } else {
          this.salesTeam.venue_service_ids = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.loadSalesTeams();
      });
    },
    getIconSalesTeam() {
      if (this.salesTeam.venue_service_ids.length == 0)
        return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.salesTeam.venue_service_ids.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    loadSalesTeams() {
      let url = `&status=${this.salesTeamStatus}&per_page=12`;
      this.showLoader("Loading SalesTeams");
      if (this.venueServiceIds.length > 0) {
        this.venueServiceIds.map((service, index) => {
          url += `&venue_service_id[${index}]=${service}`;
        });
      }
      this.$http
        .get(`venues/sales-team?page=${this.page}${url}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.salesTeamList = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    handleInputChange(index) {
      // Limit the input value to the maximum value
      if (
        this.salesTeam.commissions[index].commission_percentage !== null &&
        this.salesTeam.commissions[index].commission_percentage > this.maxValue
      ) {
        this.showError("Commession should be less than 100.");
        return;
      }
    },
    addSalesTeam() {
      this.salesTeam = {
        is_public: 1,
        first_name: null,
        last_name: null,
        designation: null,
        email: null,
        mobile: null,
        profile_image: null,
        username: null,
        password: null,
        password_confirm: null,
        hidePassword2: false,
        hidePassword1: false,
        venue_service_ids: [],
        status_id: 1,
        is_existing_user: false,
        user_id: null,
        commissions: [],
        deletedCommissions: [],
      };
      this.addSalesTeamDialog = true;
      this.editFlag = false;
      //   setTimeout(() => {
      //     this.$refs["image_upload"].cancel();
      //     this.$refs.form.resetValidation();
      //   });
    },
    setCustomerData(data) {
      console.log(data);
      if (data.email == undefined) {
        this.isExistingUser = false;
        this.salesTeam.user_id = null;
        this.salesTeam.first_name = null;
        this.salesTeam.email = null;
        this.salesTeam.last_name = null;
      } else {
        this.isExistingUser = true;
      }
      if (!data.customer_id) {
        this.$set(this.salesTeam, "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.salesTeam, "name", data.first_name);
      }

      if (
        this.salesTeam.customer_id &&
        !data.customer_id &&
        this.salesTeam.name != data.name &&
        this.salesTeam.mobile != data.mobile
      ) {
        this.$set(this.salesTeam, "mobile", null);
        this.salesTeam.search = null;
        this.salesTeam.nameSearch = null;
        this.$set(this.salesTeam, "email", null);
        this.$set(this.salesTeam, "name", null);
        this.$set(this.salesTeam, "customer_id", null);
        this.$set(this.salesTeam, "first_name", null);
        this.$set(this.salesTeam, "image_path", null);
        this.$set(this.salesTeam, "dob", null);
        this.$set(this.salesTeam, "country_id", null);
        this.$set(this.salesTeam, "last_name", null);
        this.$set(this.salesTeam, "user_id", null);
        this.$forceUpdate();
      }

      if (data.mobile) this.$set(this.salesTeam, "mobile", data.mobile);
      if (data.email) this.$set(this.salesTeam, "email", data.email);

      if (data.name) this.$set(this.salesTeam, "name", data.name);
      if (data.last_name) {
        this.$set(this.salesTeam, "last_name", data.last_name);
      } else {
        this.$set(this.salesTeam, "last_name", null);
      }
      if (data.first_name)
        this.$set(this.salesTeam, "first_name", data.first_name);
      if (data.customer_id)
        this.$set(this.salesTeam, "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.salesTeam, "image_path", data.image_path);
      } else {
        this.$set(this.salesTeam, "image_path", null);
      }
      if (data.user_id) this.$set(this.salesTeam, "user_id", data.user_id);
      this.$forceUpdate();
    },
    addOrUpdateSalesTeam(e) {
      if (e != null) {
        e.preventDefault();
        if (!this.$refs.form.validate()) {
          this.showError("Please fill all required fields");
          return;
        }
      }

      let formData = new FormData();
      this.showLoader();
      if (
        this.salesTeam.profile_image != null &&
        typeof this.salesTeam.profile_image != "string"
      )
        formData.append("image_path", this.salesTeam.profile_image);
      formData.append("is_public", this.salesTeam.is_public);
      formData.append("first_name", this.salesTeam.first_name);
      formData.append("last_name", this.salesTeam.last_name);
      formData.append("email", this.salesTeam.email);
      formData.append("mobile", this.salesTeam.mobile);
      if (this.salesTeam.username != null){
      formData.append("username", this.salesTeam.username);
       }
      formData.append("designation", this.salesTeam.designation);
      formData.append("is_existing_user", this.isExistingUser);
      if (this.salesTeam.user_id != null && this.salesTeam.user_id != undefined)
        formData.append("user_id", this.salesTeam.user_id);

      console.log(this.salesTeam.commissions);
      if (this.salesTeam.deletedCommissions.length) {
        this.salesTeam.deletedCommissions.forEach((element, index) => {
          formData.append(`deleted_commissions[${index}]`, element);
        });
      }
      this.commissionError = false;
      this.salesTeam.commissions.forEach((element, index) => {
        Object.keys(element).forEach((key) => {
          if (element[key] != null) {
            if (Number(element[key]) <= 100)
              formData.append(`commissions[${index}][${key}]`, element[key]);
            else {
              this.commissionError = true;
            }
          }
        });
      });
      if (this.commissionError) {
        this.hideLoader();
        this.showError("Commission should be less than or equal to 100");
        return;
      }
      if (this.salesTeam.password)
        formData.append("password", this.salesTeam.password);
      if (this.salesTeam.role_id)
        formData.append("role_id", this.salesTeam.role_id);

      formData.append("status_id", this.salesTeam.status_id);
      if (this.salesTeam.venue_service_ids.length)
        this.salesTeam.venue_service_ids.forEach((venueServiceId, index) => {
          formData.append(`venue_service_ids[${index}]`, venueServiceId);
        });
      this.$http
        .post(
          "venues/sales-team" +
            (this.salesTeam.id != null ? `/${this.salesTeam.id}` : ""),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message = response.data.message;
            this.showSuccess(message);
            this.hideLoader();
            this.loadSalesTeams();
            this.addSalesTeamDialog = false;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    cancelSalesTeam() {
      // this.$refs.form.resetValidation();
      this.addSalesTeamDialog = false;
      this.loadSalesTeams();

    },
    salesTeamDetails(id) {
      this.salesTeam = {
        is_public: 1,
        first_name: null,
        last_name: null,
        designation: null,
        email: null,
        mobile: null,
        profile_image: null,
        username: null,
        password: null,
        password_confirm: null,
        hidePassword2: false,
        hidePassword1: false,
        venue_service_ids: [],
        status_id: 1,
        is_existing_user: false,
        user_id: null,
        commissions: [],
        deletedCommissions: [],
      };
      if (!id) return;
      this.$http
        .get(`venues/sales-team/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.salesTeam = response.data.data;
            console.log(this.salesTeam);
            this.salesTeam.status_id = this.salesTeam.status_id.toString();
            this.salesTeam.profile_image = this.salesTeam.image_path
              ? this.salesTeam.image_path
              : null;
            this.salesTeam.venue_service_ids = this.salesTeam.sales_team_services.map(
              (item) => item.venue_service_id
            );
            if (this.salesTeam.commissions.length == 0) {
              this.salesTeam.commissions = [];
            }
            this.salesTeam.deletedCommissions = [];
            this.addSalesTeamDialog = true;
            this.editFlag = true;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    confirmDelete(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${
          data.status_id == 1 ? "delete" : "reactivate"
        } this Sales Person?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "salesTeam",
        data: {
          flag: data.flag ? true : false,
        },
      };
    },
    confirmActions(data) {
      if (data.type == "salesTeam") this.changeSalesTeamStatus(data.id);
      if (data.type == "delete_commission") {
        if (this.salesTeam.commissions[data.id].id != null) {
          this.salesTeam.deletedCommissions.push(
            this.salesTeam.commissions[data.id].id
          );
        }
        this.salesTeam.commissions.splice(data.id, 1);
        if (this.salesTeam.commissions.length == 0) {
          this.salesTeam.commissions = [
            {
              commission_percentage: null,
              product_type_id: null,
            },
          ];
        }
      }

      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    changeSalesTeamStatus(id) {
      this.$http
        .put(`venues/sales-team/${id}/status`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.loadSalesTeams();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changeActiveInactiveTab(){
       if(this.page != 1){
        this.page = 1;
      }else{
        this.loadSalesTeams();
      }
    },
    deleteCommission(index) {
      if (this.salesTeam.commissions[index].id) {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this commission?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_commission",
        };
      } else {
        this.salesTeam.commissions.splice(index, 1);
        if (this.salesTeam.commissions.length == 0) {
          this.salesTeam.commissions = [];
        }
      }
    },
    addCommission() {
      console.log(this.salesTeam.commissions);
      this.salesTeam.commissions.push({});
    },
  },
};
</script>
<style>
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.olBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
</style>
